const hasFixedMenu = document.querySelector('#has-fixed-menu');
const isValue = document.querySelector('main').classList.contains('values');

if (hasFixedMenu || isValue) {
  const homeChildContentItems = hasFixedMenu
    ? hasFixedMenu.querySelectorAll('section')
    : document.querySelectorAll('.values__item');

  const buildMenu = () => {
    const aside = document.createElement('aside');
    aside.classList.add('menu-child-section');
    aside.setAttribute('id', 'menu-section');
    aside.setAttribute('data-open', 'true');

    const ul = document.createElement('ul');
    ul.classList.add('menu-child-section__menu');

    homeChildContentItems.forEach((item) => {
      const slug = item.getAttribute('id');
      let title;

      if (isValue) {
        const slugMaj = slug.charAt(0).toUpperCase() + slug.slice(1);
        title = slugMaj;
      } else {
        const h2 = item.querySelector('h2');
        if (h2) {
          const sup = h2.querySelector('sup');
          if (sup) sup.textContent = `${sup.textContent} `;
          title = item.querySelector('h2').textContent;
        }
      }

      const li = document.createElement('li');
      li.classList.add('menu-child-section__section');

      const a = document.createElement('a');
      a.setAttribute('href', `#${slug}`);
      a.setAttribute('aria-current', false);
      a.innerHTML = title;

      li.appendChild(a);
      ul.appendChild(li);
    });

    const button = document.createElement('button');
    button.classList.add('menu-child-section__toggle');
    button.setAttribute('id', 'menu-section-toggle');
    button.setAttribute('aria-expanded', true);

    const icon = document.createElement('i');
    icon.classList.add('icon', 'icon-arrow');

    button.appendChild(icon);

    aside.appendChild(ul);
    aside.appendChild(button);

    return aside;
  };

  const isInViewport = (element, menuHeight) => {
    const rect = element.getBoundingClientRect();
    const html = document.documentElement;

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || html.clientHeight) + menuHeight &&
      rect.right <= (window.innerWidth || html.clientWidth)
    );
  };

  const activeMenuLink = (href) => {
    const menuSectionLink = document.querySelectorAll('#menu-section a');

    menuSectionLink.forEach((link) => {
      const linkHref = link.getAttribute('href');
      linkHref === `#${href}`
        ? link.setAttribute('aria-current', true)
        : link.setAttribute('aria-current', false);
    });
  };

  isValue
    ? document.querySelector('.values').prepend(buildMenu())
    : hasFixedMenu.prepend(buildMenu());
  const menuSectionToggle = document.querySelector('#menu-section-toggle');
  const menuSection = document.querySelector('#menu-section');
  const menuHeight = menuSection.offsetHeight;
  menuSection.style.marginTop = `-${menuHeight}px`;

  menuSectionToggle.addEventListener('click', () => {
    const isOpen = menuSectionToggle.getAttribute('aria-expanded') === 'true';

    menuSectionToggle.setAttribute('aria-expanded', !isOpen);
    menuSection.setAttribute('data-open', !isOpen);
  });

  window.addEventListener('scroll', () => {
    homeChildContentItems.forEach((item) => {
      const slug = item.getAttribute('id');

      if (isInViewport(item, menuHeight)) {
        activeMenuLink(slug);
      }
    });
  });
}
